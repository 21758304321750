.AssignLoadFormSec{
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: rgb(33 35 38 / 20%) 0px 10px 15px -10px;
}
.AssignLoadFormSec ::placeholder{
    color: #8F9FB4;
    opacity: 0.65;
}
.bb1pxLight{
    height: 2px;
    width: 100%;
    background: #8F9FB4;
    opacity: 0.3;
}
.AssignLoadFormSec .attachFile input[type="file"] {
    display: none;
}
.AssignLoadFormSec .attachFile label{
    width: 100%;
    height: 150px;
    border: 1px dashed #a5b4c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    
}
.createRoundSec .submitBtn {
    background: linear-gradient(to right, #38a1e6, #302c8a) !important;
    padding: 7px ;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    border: none;
}
.createRoundSec .react-datetime-picker  {
    background-color: #F6F7FB !important;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border: none !important;
}
.createRoundSec .react-datetime-picker  .react-datetime-picker__wrapper{
    border: none !important;
}
.createRoundSec .title{
    color: #8F9FB4;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
}