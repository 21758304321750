.loadBtnColor{
    color: #F6F6F6 !important;
}
.loadBtnColor-underline{
    color: #911AB0 !important;
    border: 2px solid #911AB0 !important;
}
.loadBtnBg{
    background-image: linear-gradient(to right, #38a1e6, #302c8a) !important;
}
.loadBtnBg:hover{
    opacity: 0.7;
}
.color-light{
    color: #8F9FB4;
}
.color-dark{
    color: #333333;
}
.border1-light{
   border: 1px solid #c4cdd9 !important ;
}
.border-bootom-light{
    border-bottom: 1px solid #eeeef0 !important;
}
.border-top-light{
    border-top:1px solid #eeeef0 !important;
}

.border1-light:focus,.border1-light:active{
   border: 1px solid transparent !important ;
}
.sortby-box .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: none;
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.w-200{
    width: 200%;
}
.w-190{
    width: 190%;
}
.sortby-box .btn:focus{
    box-shadow: 0 0 0 0.2rem rgba(19, 23, 255, 0.25);
}
.sortby-box table th{
    color: #8F9FB4;
    font-weight: 600;
    font-size: 13px;
    border: none !important;
    white-space: nowrap;
}
.sortby-box table td{
    border-color: #eeeef0 !important;
    white-space: nowrap;
}
.sortby-box table td .logoDivMain{
  display: flex;
  align-items: center;
}
.sortby-box table td .logoDiv{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

}

.sortby-box table td .logoDiv img{
  max-width: 50px;
  max-height: 50px;
}
.sortby-box table td div{
    color: #3C4253;  
    opacity: 1;
    font-weight: 600;
    font-size: 14px;
    width: max-content;
}
/* .sortby-box table td div:last-child{
    color: #3C4253;  
    opacity: 0.4;
    font-weight: 600;
    font-size: 12px;
    width: max-content;

} */
.w-12{
    width: 12px;
}
.w-75{
    width: 75%;
}
.fs-19{
    font-size: 19px;
}
.circle {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    position: relative;
    border: 1px solid black;
    margin-bottom: 4px;
    margin-top: 4px;
  }
.circle-light {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    position: relative;
    border: 1px solid #8F9FB4;
    margin-bottom: 4px;
    margin-top: 4px;
  }
.circle-fill {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    position: relative;
    background-color: #333333;
    border: 1px solid #333333 ;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .vertical-line-light {
    width: 2px;
    height: 40px;
    background: #8F9FB4;
  }
  .vertical-line-dark {
    width: 2px;
    height: 50px;
    background: #333333;
  }
  .font-weight-600{
    font-weight: 600;
  }
  .deadhead-mi{
    color: #3C4253;
    opacity: 0.4;
    font-weight: 600;
    font-size: 12px;
    width: max-content;
  }
  .deadhead-title{
    color: #3C4253;
    opacity: 1;
    font-weight: 600;
    font-size: 14px;
    width: max-content;
  }
  .deadhead-time{
    color: #3C4253;
    opacity: 0.4;
    font-weight: 600;
    font-size: 12px;
    width: max-content;
  }
  .pairModal {
    padding: 20px;
  }
  .pairModal .submitBtn {
    background-image: linear-gradient(to right, #38a1e6, #302c8a) !important;
    padding: 7px;
    width: 100%;
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
  }
  .pairModal .row1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }