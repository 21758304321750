.analytics-box-title{
    color: #8F9FB4;
    font-size: 11px;
    font-weight: 500;
}
.analytic-percentage{
    color: #28CB89;
    font-size: 12px;
}
.last-month{
    color: #ABB7C7;
    font-size: 11px;
}

.border-transparent{
    border: transparent !important;
}
.br-10{
    border-radius: 10px !important;
}
.mile{
    font-size: 13px;
}
.analytic-box-img{
    width: 35px ;
}
.chart-time{
    font-weight: 600;
    color: #8F9FB4;
    font-size: 13px;
}
.chart-time:hover,.chart-time:focus{
    color: #272D3B ;
    text-decoration: none;

}
.chart-time-active{
    color: #272D3B !important;
}
.chart-heading{
    font-weight: 600;
    color: #272D3B;
    font-size: 13px;
}
.bg-white{
    background: #fff !important;
}
.w-4{
    width: 4px;
}
.diver-name{
        font-weight: 600;
        color: #333333;
        font-size: 13px;
}
.driver-status-title{
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;

}
.driver-img{
    width: 2rem;
    height: 2rem;
}
.driver-dash-div{
    border-top: 1px solid #eeeef0;
}
.fs-17{
    font-size: 17px !important;
}
.fs-16{
    font-size: 16px !important;
}
.fs-15{
    font-size: 15px !important;
}
.fs-14{
    font-size: 14px !important;
}
.fs-13{
    font-size: 13px !important;
}
.fs-12{
    font-size: 12px !important;
}
.fs-11{
    font-size: 11px !important;
}
.fs-10{
    font-size: 10px !important;
}
.dark-font{
    color: #333333;
}
.status-ongoing{
    color: #17C903 !important;
}
.status-ended{
    color: #F51F3C !important;
}
.status-offline{
    color: #FFA500 !important;
}
.overflow-hidden{
    overflow: hidden;
}
/* chart style */
.chart-area svg{
    width: 100%;
    height: 100%;
}
.chart-area path{
    stroke-width: 2;
}
.chart-area circle{
    width: 0;
}
.chart-area tspan{
    font-size: 14px;
    font-weight: 700;
    color: #272D3B;
}
.h-100{
    height: 100%;
}