/* user styles */


  /* @font-face { font-family: "Somerton Dense"; src: url('./somerton_dense/Somerton Dense df.otf'); }  */
  /* @font-face { font-family: "Poppins"; src: url('./Fonts/Poppins/Poppins-Regular.ttf'); }  */
  /* @font-face { font-family: "PoppinsBold"; src: url('./Fonts/Poppins/Poppins-Bold.ttf'); }  */
  /* @font-face { font-family: "Montserrat"; src: url('./Fonts/Montserrat/Montserrat-SemiBold.ttf'); }  */
  /*@font-face { font-family: "Montserrat"; src: url('./Fonts/Montserrat/Montserrat-Bold.ttf'); }*/
  @font-face{ font-family: "chillax"; src: url('./Fonts/Chillax-Medium.otf'); } 
  @font-face{ font-family: "lato"; src: url('./Fonts/Lato/Lato-Regular.ttf'); } 
  @font-face{ font-family: "latoBold"; src: url('./Fonts/Lato/Lato-Bold.ttf'); } 
  @font-face{ font-family: "camptonBold"; src: url('./Fonts/campton/CamptonBold.otf'); } 
:root{
    --theme-color:#001B3D;
    --theme-blue:#05B4EF;
}
    html{
        scroll-behavior: smooth;
    }
    body{
        background: linear-gradient( 68.4deg,  rgb(248 182 204 / 49%) 0.5%, rgb(192 198 230 / 55%) 49%, rgb(225 246 240 / 59%) 90% );
        font-family: lato !important;
        /* font-family: chillax !important; */
        padding: 0;
        margin: 0;
    }
    #root {
        overflow: hidden;

    }
    .td-none {
        text-decoration: none !important;
    }
    h1,h2,h3,h4,h5,h6{
        /* font-family: MariWeather !important; */
    }

    .montserat{
        /* font-family: Montserrat !important; */
    }
    button{
        /* font-family: Montserrat !important; */
    }
    .montseratMedium{
        /* font-family: MontserratMedieum !important; */
    }
    p{
        /* font-family: MontserratMedieum !important; */
    }
   
    .back-color{
        color: #080027 !important;
    }
    .userContentMinHeight {
        min-height: calc(100vh - 220px);
    }
    .vertical-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
.ai-center {
    align-items: center;
}
.jc-center {
    justify-content: center;
}
.custom-nav .navbar-brand {
    width: 135px;
}
.navbar {
    max-width: 1350px;
    margin: auto;
}
.custom-nav .nav-link {
    color: #17447E !important;
    font-size: 17px;
    font-weight: 500;
    transition: all .3s;
    background: linear-gradient(270deg,#2f2b85 0,#3ea7f9) 0 100% transparent no-repeat;
    background-size: 0% 2px;
    font-family: latoBold;
}
.custom-nav .nav-link:hover {
    transition: all .3s;
    background: linear-gradient(270deg,#2f2b85 0,#3ea7f9) 0 100% transparent no-repeat;
    background-size: 100% 2px;
}
.custom-nav .walletBtn {
    background: #17447E;
    font-size: 16px;
    font-weight: bold;
    color: white;
    padding: 10px 40px;
    border-radius: 12px;
    position: relative;
}
.custom-nav .walletBtn:hover {
    background: var(--theme-blue);
    color: #fff;
}
.custom-nav .walletBtn:hover .logout {
    display: block;
}
.custom-nav .walletBtn .logout {
    position: absolute;
    background-color: #fff;
    padding: 7px 10px;
    left: 0;
    bottom: -39px;
    right: 0;
    color: #000;
    font-weight: 500;
    border-radius: 12px;
    display: none;
}

footer {
    max-width: 1350px;
    margin: auto;
    background-color: #fff;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
}
footer .r1 {
    padding: 30px 25px 15px 25px;
}
 footer .innerFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    /* border-bottom: 1px solid #ADB1C2; */
  }
  
 footer .socialMain a {
    display: inline-block;
    margin-left: 3px;
    margin-bottom: 5px;
    transition: all 0.3s;
  }
  
 footer .socialMain a:hover {
    transform: scale(1.2);
    transition: all 0.3s;
  }
  
 footer .socialMain a img {
    width: 40px;
  }
  
 footer img {
    width: 130px;
  }
  
 footer .copyRight {
    padding: 20px;
    color: #17447E;
    text-align: center;
    border-top: 2px solid #D7D7D7;
  }
  
 footer .quickLinks{
    /* font-family: campton; */
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: var(--theme-blue);
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: capitalize;
  
  }
 footer .desc{
    color: #17447E;
    font-family: 'latoBold';
  }
 footer ul{
    list-style: none;
    padding-left: 0 !important;
  }
 footer ul li{
    padding: 10px 0;
  }
 footer ul li a{
    color: #17447E ;
    cursor: pointer;
    font-family: 'latoBold';
}
a:not([href]):not([class]) {
    color: #17447E !important;
  }
 footer ul li a:hover{
    opacity: 0.8;
    text-decoration: none;
  }
 footer .subsMain button:hover {
    background: #fff;
    color: var(--theme-blue);
  }
 footer .subsMain button {
    background: var(--theme-blue);
    border: none;
    /* font-weight: bold; */
    padding: 7px 10px;
    color: #fff;
  }
 footer .subsMain input{
    border: none;
    width: 100%;
    background: #F2F2F2;
    outline: none;
    border: none;
    padding-left: 10px;
  }
 footer .subsMain{
    background: #F2F2F2;
    max-width: 350px;
    display: flex;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
  }

.homePage .bannerSec h1{
    /* font-family: chillax; */
    font-family: camptonBold;
    font-weight: 900;
    color: #007AFF;
    font-size: 56px;
    margin-bottom: 0;
    text-align: left;
}
.homePage .bannerSec .Typist .Cursor{
    display: none !important;
}
.homePage .bannerSec h1 span{
    color: #001234;
}
.homePage .bannerSec {
    background: url(./images/userImages/banner.png);
    height: 520px;
    background-position: center;
    background-size: cover;
    margin: 40px 0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 40px 60px;
}
.homePage .chooseMarket {
    padding: 50px 0;
}
.homePage h1 {
    font-size: 60px;
    text-align: center;
    color: var(--theme-color);
    text-transform: uppercase;
    font-family: camptonBold, serif;
}
.homePage .headTitle {
    font-size: 20px;
    text-align: center;
    color: #33609A;
}
.homePage .chooseMarket .marketCard {
    position: relative;
    border-radius: 40px;
    background-color: #fff;
    padding: 25px 15px;
    color: #001B3D;
    top: 0;
    transition: all 0.3s;
    margin-bottom: 20px;
}
.homePage .chooseMarket .marketCard:hover {
    box-shadow: 0 9px 11px -3px rgb(8 0 17 / 25%);
    top: -10px;
    transition: all 0.3s;
}
.homePage .chooseMarket .marketCard .col1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.homePage .chooseMarket .marketCard .col1 .iconDiv{
    height: 45px;
    width: 45px;
}
.homePage .chooseMarket .marketCard .col1 .iconDiv img{
    max-height: 100%;
    max-width: 100%;
}
.homePage .chooseMarket .marketCard .currencyTitle{
    font-size: 15px;
}
.homePage .chooseMarket .marketCard .currencyAmount{
    font-size: 30px;
    display: inline-block;
    background: linear-gradient(68.4deg, rgb(248 182 204 / 49%) 0.5%, rgb(192 198 230 / 55%) 49%, rgb(225 246 240 / 59%) 90%);
    padding: 9px 16px;
    border-radius: 15px;
    font-weight: 500;
}
.homePage .chooseMarket .marketCard .timeDivMain{
    margin: 20px 0;
}
.homePage .chooseMarket .marketCard .timeDivMain .msg{
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #ffffff70;
    margin-top: 5px;
}
.text-uppercse {
    text-transform: uppercase;
}
.homePage .chooseMarket .marketCard .timeDiv{
    display: flex;
    justify-content: center;
    color: #001B3D;
    border-radius: 15px;
    min-width: 100%;
}
.homePage .chooseMarket .marketCard .time{
    padding: 0px 10px;
    position: relative;
    min-width: 52px;
    text-align: center;
}
.homePage .chooseMarket .marketCard .themeBtn {
    background: var(--theme-blue);
    font-size: 16px;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 12px;
    position: relative;
    text-align: center;
    margin-top: 20px;
    transition: all 0.3s;
}
.homePage .chooseMarket .marketCard .themeBtn:hover {
    background-color: var(--theme-color);
    transition: all 0.3s;
}
.homePage .chooseMarket .marketCard .time h1{
    color: #001B3D !important;
    font-size: 30px;
    margin-bottom: -9px;
    font-weight: 500!important;
    text-align: center;
    font-family: latoBold, serif;
    border-bottom: 2px solid #D7D7D7;
    margin-bottom: 1px;
    padding-bottom: 2px;
}

.DetailPage .timeDivMain{
    margin: 10px 0;
}
.DetailPage .timeDivMain .msg{
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #ffffff70;
    margin-top: 5px;
}
.DetailPage .timeDivMain .timeDiv{
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 15px;
    min-width: 100%;
}
.DetailPage .timeDivMain .time{
    padding: 0px 7px;
    position: relative;
    min-width: 52px;
    text-align: center;
}
.DetailPage .timeDivMain .time h1{
    color: var(--theme-color);
    font-size: 32px;
    margin-bottom: -9px;
    font-weight: 500!important;
    text-align: center;
    font-family: latoBold, serif;
}
.DetailPage .timeDivMain .time span{
    font-weight: 600;
    font-size: 9px;
    color: var(--theme-color);
}
.font-capitalize {
    text-transform: capitalize;
}
.homePage .chooseMarket .marketCard .time span{
    font-weight: 600;
    font-size: 10px;
    
}
.homePage .chooseMarket .marketCard .currencyAmount::first-letter{
    font-size: 37px;
}
.homePage .chooseMarket .marketCard .col1 .liveBtn{
    background: var(--theme-blue);
    border: none;
    color: white;
    min-width: 70px;
    height: 32px;
    border-radius: 7px;
    font-size: 14px;
    margin-right: 5px;
    text-transform: capitalize;
}
.homePage .chooseMarket .marketCard .col1 .weeklyBtn{
    background: #9C9C9C;
    border: none;
    color: white;
    min-width: 70px;
    height: 32px;
    border-radius: 7px;
    font-size: 14px;
    text-transform: capitalize;
}
.slick-prev, .slick-next {
    z-index: 1000;
}
.homePage .chooseMarket .marketCard .col2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 100px; */
}
.homePage .chooseMarket .marketCard .col2 p {
    margin-bottom: 0;
}
.homePage .chooseMarket .marketCard .col2 .amount {
    font-size: 25px;
}
.homePage .chooseMarket .marketCard .col2 .amount::first-letter {
    font-size: 30px;
}
.homePage .chooseMarket .marketCard .col2 .amount1::first-letter {
    font-size: 30px;
}
.homePage .chooseMarket .marketCard .col2 .title {
    font-size: 14px;
    font-weight: 500;
}
.homePage .chooseMarket .upcomingBanner {
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
}
.homePage .chooseMarket .upcomingBanner img {
    max-width: 100%;
}
.text-right {
    text-align: right;
}
.homePage .crptoMarket {
    padding: 40px 0; 
}
.homePage .crptoMarket .filterRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.homePage .crptoMarket .filterRow p{
    margin-bottom: 0;
}
.homePage .crptoMarket .filterRow .head{
    margin: 0;
    color: #17447E;
    font-weight: 500;
    margin-right: 15px;
}
.homePage .crptoMarket .filterRow .col1{
    display: flex;
    align-items: center;
}
.homePage .crptoMarket .filterRow .col1 .select{
    background: #17447e21;
    border: none;
    color: #17447E;
    padding: 8px 14px;
    border-radius: 8px;
}
.homePage .crptoMarket .filterRow .col2{
    display: flex;
    justify-content: flex-end;
    flex: auto;
}
.homePage .crptoMarket .filterRow .col2 .linkMain{
    display: flex;
    justify-content: space-between;
    max-width: 270px;
    width: 100%;
}
.homePage .crptoMarket .filterRow .col2 .fLink{
   color: #17447e87;
   font-size: 18px;
}
.homePage .crptoMarket .filterRow .col2 .fLink:focus{
   color: #17447e;
   box-shadow: none;
}
 .crptoMarket .marketTable{
   margin: 10px 0;
   border-radius: 20px;
    overflow: hidden;
}
 .crptoMarket table thead tr{
   background: #17447e;
}
 .crptoMarket table thead tr th{
    color: #fff;
    font-size: 18px;
    vertical-align: middle;
    font-weight: 500;
}
 .crptoMarket table tr td{
    color: #17447E;
    font-size: 15px;
    vertical-align: middle;
    border: none;
}
 .crptoMarket table tr td a{
    color: #17447E;
    font-size: 15px;
    vertical-align: middle;
    border: none;
}
 .crptoMarket table tr .cIcon{
    max-height: 40px;
    max-width: 100px;
    margin-right: 10px;
}
 .crptoMarket table tr .cIcon2{
    max-height: 25px;
    max-width: 50px;
    margin-right: 10px;
}

 .crptoMarket table tr th, .crptoMarket table tr td{
    padding: 20px;
}
 .crptoMarket table tbody tr th, .crptoMarket table tbody tr td:first-child{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
 .crptoMarket table tbody tr th, .crptoMarket table tbody tr td:last-child{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
 .crptoMarket .table-striped>tbody>tr:nth-of-type(even)>* {
    background: var(--theme-blue);
}
 .crptoMarket .table-striped>tbody>tr:nth-of-type(odd)>* {
    box-shadow: none;
}
 .crptoMarket .table-striped>tbody>tr:nth-of-type(even) td {
    color: #fff;
}
 .crptoMarket .table-striped>tbody>tr:nth-of-type(even) td a{
    color: #fff;
}
 .crptoMarket .marketTable .LiveBtn{
    background: var(--theme-color);
    border: 2px solid var(--theme-color);
    padding: 3px 18px;
    color: #fff;
    border-radius: 7px;
    transition: all 0.3s;
}
 .crptoMarket .marketTable .LiveBtn:hover{
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: #fff;
    transition: all 0.3s;
}
 .crptoMarket .marketTable .LiveBtn:active{
    background: var(--theme-color);
    color: #fff;
}

.crptoMarket .marketTable .pridictBtn{
    background: var(--theme-color);
    border: 2px solid var(--theme-color);
    padding: 4px 18px;
    color: #fff;
    min-width: 112px;
    border-radius: 23px;
    text-decoration: none;
    transition: all 0.3s;
}
.minW112 {
    min-width: 112px;
    text-align: center;
    display: inline-block;
    font-weight: 700;
}
.crptoMarket .marketTable .pridictBtn:active{
    background: var(--theme-color);
    color: #fff;
}
.crptoMarket .marketTable .pridictBtn:hover{
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: #fff;
    transition: all 0.3s;
}
.DetailPage {
    padding: 30px 0;
    padding-bottom: 80px;
    color: var(--theme-color);
    position: relative;
}
.DetailPage p{
    margin-bottom: 0;
}
.DetailPage .headingRow {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
    margin-top: 110px;
    flex-direction: column;
    align-items: unset;
}
.DetailPage .headingRow .mainHeading{
    color: #17447E;
    font-size: 36px;
    font-family: camptonBold, serif;
}
.DetailPage .headingRow .mainHeading span{
    color: var(--theme-blue);
}
.DetailPage .headingRow .price{
    font-size: 25px;
}
.DetailPage .headingRow .price::first-letter{
    font-size: 30px;
}
.DetailPage .headingRow .nowDiv{
    text-align: right;
}

.DetailPage .now{
    background: var(--theme-blue);
    color: #fff;
    border: none;
    border-radius: 7px;
    height: 38px;
    min-width: 80px;
    margin-bottom: 8px;
    padding: 6px 16px;
    font-family: latoBold,serif;
}
.DetailPage .weekly{
    /* background: #17447e50; */
    background: #9C9C9C;
    color: #fff;
    border: none;
    border-radius: 7px;
    height: 38px;
    min-width: 80px;
    margin-bottom: 8px;
    margin-left: 10px;
    padding: 6px 16px;
    font-family: latoBold,serif;
    
}
.DetailPage .row2 {
    /* display: flex;
    justify-content: space-between;
    align-items: flex-end; */
    margin-top: 70px;
}
.DetailPage .row2 .priceDiv h2{
    font-family: camptonBold, serif;
    font-size: 30px;
    line-height: 1;
    margin-bottom: 3px;
}
/* .DetailPage .row2 .priceDiv h2::first-letter{
    font-size: 36px;
} */
.DetailPage .row2 .timeDivMM{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fff;
    box-shadow: 3px 7px 6.1px 0px #0030411F;
    border-radius: 18px;
    height: 100%;
}
.DetailPage .row2 .timeDiv{
    text-align: right;
}
.DetailPage .row2 .col1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fff;
    box-shadow: 3px 7px 6.1px 0px #0030411F;
    border-radius: 18px;
}
.DetailPage .row2 .col1 .priceDiv {  
    text-align: center;
}
.DetailPage .row2 .col1 .priceDiv p { 
    font-size: 14px;
}
.DetailPage .row2 .col1 .price {
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
}

.DetailPage .row2

.w-mc {
    width: max-content;
}
.DetailPage .pridictCol .heading{
    font-size: 35px;
    text-align: center;
    margin-bottom: 15px;
}
.DetailPage .pridictCol .pCard .innerCard{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DetailPage .pridictCol .pCard{
    border-radius: 30px;
    padding: 23px 15px;
    background: rgba(0,0,0,0.03);
    backdrop-filter: blur(40px);
    margin-bottom: 15px;
}
.payMain{
    margin-top: 10px;
    display: flex;
}
.payMain .title {
    font-size: 25px;
    text-align: center;
    margin-bottom: 10px;
    font-family: latoBold, serif;
}
.payMain .plotCard{
    padding: 20px 40px;
    background: white;
    margin-top: 10px;
    border-radius: 15px;
    width: 55%;
}
.payMain .plotCard .row1{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.payMain .plotCard .row2{
    margin-top: 0;
    display: flex;
    justify-content: space-between;
}
.payMain .plotCard .row2 img{
    width: 28px;
}
.payMain .plotCard .row2 button{
    font-weight: bold;
    padding-left: 0;
}
.payMain .plotCard .row2 p{
    font-size: 30px;
    color: var(--theme-blue);
}
.payMain .plotCard .row2 input{
    font-size: 20px;
    color: var(--theme-blue);
    width: 100%;
    border: none;
    text-align: right;
    border-radius: 10px;
    /* border: 1px solid rgb(118, 118, 118); */
    max-width: 100px;
    background: #F3F3F3;
    text-align: center;
}
.payMain .plotCard .row2 input::placeholder {
    color: #9C9C9C;
}
.payMain textarea::placeholder {
    color: #9C9C9C;
}
.payMain textarea{
    font-size: 15px;
    color: var(--theme-blue);
    width: 100%;
    border-radius: 10px;
    border: none;
    background: #F3F3F3;
    padding: 10px;
    /* text-align: right; */
}
.payMain .plotCard .text{
    font-size: 14px;
    font-weight: 500;
    font-family: latoBold, serif;
}
.payMain .plotCard .tubBtnDiv{
    display: flex;
    margin-top: 5px;
}
.payMain .plotCard .tubBtnDiv button:focus{
    box-shadow: none;
}
.payMain .plotCard .tubBtnDiv button{
    background: var(--theme-blue);
    padding: 6px 18px;
    font-size: 16px;
    color: #fff;
    margin-right: 5px;
    min-width: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}
.payMain .plotCard .tubBtnDiv button i{
    font-size: 15px;
    margin-right: 7px;
}
.bg-dark{
    background: #001B3D;
}
.payMain .youReciveDiv{
    padding: 20px 40px;
    /* border-top: 1px solid rgba(0,0,0,0.1); */
    /* border-bottom: 1px solid rgba(0,0,0,0.1); */
    font-size: 13px;
    width: 45%;
}
.payMain .youReciveDiv p{
    text-align: center;
    margin-bottom: 0;
    font-size: 16px;
    font-family: latoBold, serif;
}
.payMain .youReciveDiv .price {
    font-size: 26px; 
    color: var(--theme-blue);
}
.payMain .youReciveDiv .expectedM {
    background: #F3F3F3;
    padding: 28px 20px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}
.payMain .verticleLine {
    width: 2px;
    height: 200px;
    background-color: #D7D7D7;
    align-self: center;
}
.analyzeModal .submitBtnDiv{
    margin-top: 20px;
    
}
.analyzeModal .submitBtn {
    background: #17447E;
    border-radius: 15px;
    width: 200px;
    height: 60px;
    font-size: 17px;font-weight: 500;
    display: flex;justify-content: center;
    align-items: center;
    color: #fff;
    margin: auto;
    cursor: pointer;
    transition: all 0.3s;
}
.analyzeModal .submitBtn:hover {
    background: var(--theme-blue);
    color: #fff;
    transition: all 0.3s;
}
.DetailPage .pridictCol .col1{
    text-align: center;
}
.DetailPage .pridictCol .col1 h5{
    font-size: 16px;
}
.DetailPage .pridictCol .col1 img{
    width: 45px;
    margin-bottom: 5px;
}
.DetailPage .pridictCol .col1 p{
    font-size: 14px;
}
.DetailPage .pridictCol .col2 {
    font-size: 14px;
    text-align: right;
}
.DetailPage .pridictCol .col2 p span{
    color: var(--theme-blue);
}
.DetailPage .pridictCol .col2 .plotPrice{
    margin-top: 60px;
}
.DetailPage .analyticsCard{
    /* background: #001B3D; */
    background: #1f1f32;
    padding: 20px;
    border-radius: 30px;
    margin-top: 40px;
    color: #fff;
}
.DetailPage .analyticsCard .row1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DetailPage .analyticsCard .row1 .col1{
    display: flex;
}
.DetailPage .analyticsCard .row1 .col1 .imgDiv{
    width: 60px;
    margin-right: 10px;
}
.DetailPage .analyticsCard .row1 .col1 .title h5{
    margin: 0;
    font-size: 30px;
}
.DetailPage .analyticsCard .row1 .col1 .title p{
    text-transform: uppercase;
}
.DetailPage .analyticsCard .row1 .col2{
    text-transform: uppercase;
}
.DetailPage .analyticsCard .row1 .col2 select{
    padding: 6px;
    width: 120px;
    background: transparent;
    color: #fff;
    border-radius: 8px;
}
.DetailPage .analyticsCard .chartMain{
    /* height: 350px; */
    /* margin-top: 40px; */
}
.DetailPage .analyticsCard .chartMainInner{
    height:470px;
     background-color: 
     #1D2330; 
     overflow:hidden; 
     box-sizing: border-box; 
     border: 1px solid #282E3B; 
     border-radius: 4px; 
     text-align: right; 
     line-height:14px; 
     font-size: 12px; 
     font-feature-settings: normal; 
     text-size-adjust: 100%; 
     /* box-shadow: inset 0 -20px 0 0 #262B38; */
     padding:1px;padding: 0px; 
     margin: 0px;
      width: 100%;
}
.DetailPage .analyticsCard .chartMainInner .chartDiv{
    height:540px; padding:0px; margin:0px; width: 100%;
}
.DetailPage .crptoMarket {
    margin-top: 50px;
}
.DetailPage .crptoMarket .heading{
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 20px;
}
.DetailPage .layer {
    position: absolute;
    right: -100px;
    top: 80px;
}
.DetailPage .ball {
    position: absolute;
    right: -70px;
    top: 40%;
    transform: translate(-50%, -50%);
}
.w-30 {
    width: 30px;
}

.DetailPage .dougnut {
    position: absolute;
    right: 0px;
    top: 940px;
    transform: translate(0%, -50%);
    width: 250px;
}
.DetailPage .cross {
    position: absolute;
    right: 150px;
    bottom: -70px;
    width: 50px
}
.DetailPage .triangle {
    position: absolute;
    right: 0px;
    top: 56%;
    width: 170px;
}
.pr {
    position: relative;
}
.d-flex {
    display: flex;
}
.jc-sb {
    justify-content: space-between;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}
.WalletModal {
    color: #17447E;
}
.WalletModal h1{
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-family: camptonBold, serif;
}
.WalletModal .walletRow{
    display: flex;
    justify-content: center;
}
.WalletModal .metamask{
   /* margin-right: 25px; */
}
.WalletModal .walletImg .img-fluid{
    height: 100%;
}
.WalletModal .walletImg{
    width: 230px;
    margin: auto;
    margin-bottom: 30px;
    height: 120px;
    text-align: center;
}
.WalletModal .walletConnect .walletImg{
    width: 226px;
    margin: auto;
    margin-bottom: 30px;
}
.WalletModal .btnDiv {
    text-align: center;
    margin-bottom: 30px;
}
.WalletModal .btnDiv button{
    background: var(--theme-blue);
    width: 180px;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    color: #fff;
    margin: auto;
    transition: all 0.3s;
}
.WalletModal .btnDiv button:hover{
    background: var(--theme-color);
    transition: all 0.3s;
}
.WalletModal .helpDv{
    font-size: 14px;
    text-align: center;
    padding-bottom: 30px;
}
.WalletModal .helpDv img{
    width: 20px;
    margin-right: 5px;
}
.WalletModal .closeIcon{
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
}
.WalletModal .closeIcon i{
    font-size: 25px;
    color: #707070;
    cursor: pointer;
}
.modal-dialog {
    max-width: 800px !important;
}
.modal-content {
    border-radius: 40px !important;
}
.db-sm {
    display: none;
}
.db-lg {
    display: block;
}

.DetailPage .analyzNewMain {
    padding: 25px 40px;
    background-color: #fff;
    border-radius: 40px;
    margin-top: 50px;
}
.DetailPage .analyzNewMain .head {
font-family: camptonBold, serif;
    font-size: 34px;
    text-align: center;
    margin-bottom: 10px;
}
.DetailPage .analyzNewMain .analyzCard {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    background-color: #F3F3F3;
    border-radius: 30px;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
}
.DetailPage .analyzNewMain .analyzCard:hover {
    box-shadow: 3px 7px 6.1px 0px #0030411F;
    transition: all 0.3s;
}
.DetailPage .analyzNewMain .analyzCard h5 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
}
.DetailPage .analyzNewMain .analyzCard p {
    /* line-height: 1.2; */
    font-family: latoBold, serif;
}

.DetailPage .analyzNewMain .analyzCard .col2 {
    text-align: center;
}
.DetailPage .analyzNewMain .analyzCard .imgDiv {
    width: 70px;
    margin: auto;
    margin-bottom: 4px;
	animation: beat2 .5s infinite alternate;
	transform-origin: center;
}

.beatAnimation {
    animation: beat2 .5s infinite alternate;
}

/* Heart beat animation */
@keyframes beat2{
	to { transform: scale(1.2); }
}
.DetailPage .analyzNewMain .analyzCard .imgDiv img {
    width: 100%;
}
.analyzeModal {
    position: relative;
}
.analyzeModal .sideImg {
    position: absolute;
    left:0;
    top: 17px;
}

.tradingview-widget-container {
    height: 460px;
}
.tradingview-widget-container #tradingview_dbed6 {
    height: 100%;
}



@media(min-width:1400px) {
    .homePage .bannerSec {
        height: 450px;
    }
    .homePage .container {
        max-width: 1340px;
    }
    .homePage .chooseMarket .marketCard {
        padding: 30px 20px;
    }
    .homePage .chooseMarket .marketCard .col2 {
        /* margin-top: 120px; */
    }
    .DetailPage .headingRow .mainHeading {
        font-size: 34px;
    }
}
@media(max-width:1200px) {
    .DetailPage .ball {
        display: none;
    }
    .DetailPage .dougnut {
        display: none;
    }
    .DetailPage .cross {
        display: none;
    }
    .DetailPage .triangle {
        display: none;
    }
}
@media(max-width:768px) {
   
    .DetailPage .analyticsCard .row1 {
        flex-direction: column;
        align-items: flex-start;
    }
    .DetailPage .analyticsCard .row1 .col2 {
        margin-top: 10px;
    }
    .homePage .crptoMarket .filterRow {
        flex-direction: column;
    }
    .homePage .crptoMarket .filterRow .col1 {
        width: 100%;
       
    }
    .homePage .crptoMarket .filterRow .col2 {
        width: 100%;
        justify-content: flex-start;
    }
}

@media(max-width:576px) {
    .px-xs-18 {
        padding-left: 18px;
        padding-right: 18px;
    }
    .db-lg {
        display: none;
    } 
    .db-sm {
        display: block;
    }
    .WalletModal .helpDv {
        font-size: 12px;
    }
    .WalletModal .btnDiv button {
        font-size: 13px;
        width: 115px;
        height: 40px;
    }
    .WalletModal .walletImg {
        width: 90px;
        height: 80px;
        margin-bottom: 20px;
    }
    .WalletModal .walletConnect .walletImg{
        width: 95px;
        margin-bottom: 20px;
    }

   
    .WalletModal .metamask {
        margin-right: 5px;
    }
    .WalletModal h1 {
        font-size: 25px;
    }
    .DetailPage .analyticsCard .row1 .col1 .title h5 {
        font-size: 24px;
    }
    .DetailPage .crptoMarket .heading {
        font-size: 24px;
    }
    .DetailPage .headingRow .mainHeading {
        font-size: 24px;
    }
    .DetailPage .headingRow {
        flex-direction: column;
        align-items: unset;
    }
    .homePage .chooseMarket .marketCard {
        margin-bottom: 20px;
    }
    .homePage h1 {
        font-size: 32px;
    }
    .homePage .headTitle {
        font-size: 16px;
    }
    .homePage .crptoMarket .filterRow {
        font-size: 14px;
    }
    .homePage .crptoMarket .filterRow .col2 .fLink {
        font-size: 15px;
    }
    .payMain {
        flex-direction: column;
    }
    .payMain .verticleLine {
        height: 2px;
        width: 80%;
    }
    .payMain .plotCard {
        width: 100%;
        padding: 20px;
    }
    .payMain .youReciveDiv {
        width: 100%;
        padding: 20px;
    }
    .DetailPage .row2 .timeDivMM{
        flex-direction: column;
    }
    .DetailPage .analyzNewMain {
        padding: 20px;
    }
    .DetailPage .analyzNewMain .analyzCard .imgDiv {
        font-size: 60px;
    }
    .DetailPage .analyzNewMain .analyzCard h5 {
        font-size: 23px;
    }
    .DetailPage .analyzNewMain .analyzCard p {
        font-size: 14px;
    }
}


@media(min-width:1200px) and (max-width:1400px) {
    .homePage .bannerSec {
        height: 400px;
    }
    .homePage .bannerSec h1 {
        font-size: 50px;
    }
    .navbar {
        max-width: 1140px;
    }
    footer {
        
        max-width: 1140px;
    }
}
@media(min-width:992px) and (max-width:1200px) {
    .homePage .bannerSec {
        height: 360px;
    }
    .homePage .bannerSec h1 {
        font-size: 42px;
    }
    .homePage .chooseMarket .marketCard .time {
        padding: 0 6px;
        min-width: 46px
    }
    .homePage .chooseMarket .marketCard .time span {
        font-size: 9px;
    }
    .homePage .chooseMarket .marketCard .time h1 {
        font-size: 30px;
    }
}
@media(max-width:992px) {
    .homePage .chooseMarket .chooseImg {
        margin-top: 20px;
    }
}
@media(min-width:768px) and (max-width:992px) {
    .homePage .bannerSec {
        height: 250px;
    }
    .homePage .bannerSec h1 {
        font-size: 35px;
    }
}
@media(min-width:576px) and (max-width:768px) {
    .WalletModal .btnDiv button {
        font-size: 14px;
        width: 160px;
    }
    .WalletModal .walletImg{
        width: 180px;
        margin: auto;
        margin-bottom: 30px;
        height: 120px;
        text-align: center;
    }
    .WalletModal .walletConnect .walletImg {
        width: 180px;
        margin: auto;
        margin-bottom: 30px;
        height: 120px;
        text-align: center;
    }
    .WalletModal .metamask {
        margin-right: 10px;
    }
    .homePage .bannerSec {
        height: 240px;
        padding: 30px;
    }
    .homePage .bannerSec h1 {
        font-size: 30px;
    }
    .payMain .youReciveDiv {
        padding: 20px;
    }
    .payMain .plotCard {
        padding: 20px;
    }
}

@media(max-width:576px) {
    .WalletModal .walletRow {
        justify-content: space-around;
    }
    .homePage .bannerSec {
        height: 180px;
        padding: 30px 20px ;
    }
    .homePage .bannerSec h1 {
        font-size: 30px;
    }
    .DetailPage .analyticsCard {
        padding: 20px 0px;
    }
    .DetailPage .analyticsCard .row1 {
        padding: 0 10px;
    }
    .DetailPage .analyticsCard .chartMain {
        /* height: 300px; */
    }
    .DetailPage .analyticsCard .row1 .col1 .title p {
        font-size: 14px;
    }
}
@media(max-width:400px) {
    .homePage .bannerSec h1 {
        font-size: 29px;
    }
}

@media screen and (min-width:340px) and (max-width:380px) {
    .WalletModal .btnDiv button {
        font-size: 13px;
        width: 100%;
        max-width: 110px;
        height: 40px;
        padding: 1px 10px;
    }
    .WalletModal .walletImg {
        width: 76px;
        height: 80px;
        margin-bottom: 20px;
    }
    .WalletModal .walletConnect .walletImg{
        width: 95px;
        margin-bottom: 20px;
    }
}
@media(max-width:340px) {
    .WalletModal .btnDiv button {
        font-size: 12px;
        width: auto;
        height: 37px;
        padding: 1px 10px;
    }
    .WalletModal .walletImg {
        width: 70px;
    }
    .WalletModal .walletConnect .walletImg{
        width: 80px;
    }
}
  