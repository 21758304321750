.dib{
    display: inline-block;
}
.drivers-row .driver-row-link{
    color: #3C4253;
    font-size: 14px;
    font-weight: 600;
    opacity: 0.4;
    text-decoration: none;
}
.drivers-row .driver-row-link:hover,.drivers-row .driver-row-link:focus{
    opacity: 0.8;
    color: #3C4253;
    text-decoration: none;
    
}
.vertically-center{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.border-white{
    border-color: white;
}
.text-end{
    text-align: end;
}
.driver-2nd-col{
    text-align: end;
}
@media(max-width:992px){
    .drivers-row h4{
        margin-right: 0 !important;
    }
}
.drivers-table .img-profile {
    width: 2rem;
    height: 2rem;
    margin-right: 10px;
}
.table-text-dark{
    color: #3C4253 !important;
    opacity: 1;
    font-weight: 600;
    font-size: 14px;
    width: max-content;
}
.table-text-light{
    color: #3C4253 !important;
    opacity: 0.4;
    font-weight: 600;
    font-size: 12px;
    width: max-content;
}
.w-max-c{
    width: max-content;
}
.db{
    display: block;
}
.drivers-row label{
    padding: 6px 12px;
    display: table;
    color: black;
  cursor:pointer;
    border-radius:.25rem;
}
.drivers-row input[type="file"] {
    display: none;
}
.jc-fe{
    justify-content: flex-end;
}
.diverAddBtn{
    width: 80px;
    height: 80px;
    background-image: linear-gradient(to right, #38a1e6, #302c8a) !important;
    display: flex;justify-content: center;
    align-items: center;
    position: fixed;
    right: 25px;
    bottom: 50px;
    border-radius: 50%;
    box-shadow: 0 10px 16px -8px black;
}
.diverAddBtn:hover{
   opacity: 0.7;
}